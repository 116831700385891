import axios from '@/axios/globalaxios'

export const sysRoles = data =>{
    return axios({
        url: "/tradelab/sysRole/sysRoles",
        method: 'post',
        data:data
    })
}

export const deleteRole = data =>{
    return axios({
        url: "/tradelab/sysRole/delete",
        method: 'post',
        data:data
    })
}


// 查询 文档 数据列表
export const findDocumentSharing = data =>{
    return axios({
        url: "/tradelab/documentSharing/findDocumentSharing",
        method: 'post',
        data:data
    })
}



// 删除 文档 数据列表
export const delFile = data =>{
    return axios({
        url: "/tradelab/documentSharing/delDocumentSharing",
        method: 'get',
        params:data
    })
}
