import {
  sysUsers,
  deleteInfo,
  saveSysUserRole,
  registerButtonSwitch,
  importUsersInBulk,
  resetPassword,
} from "./userApi";
import {
  ElMessage
} from "element-plus";
import {
  dealDate
} from "../../course-manage/trade-manage/tradeMethods";
import {
  ElMessageBox
} from "element-plus";
import {
  sysRoles
} from "../role-manage/roleApi";
import {
  getSchools
} from "@/views/system-manage/school-manage/schoolApi.js"
import {
  nextTick
} from "vue";
// import adminTemplate from "/static/userTemplate.xlsx"
export const getUserLists = (data) => {
  data.loading = true;
  let parame = {
    name: data.userInput,
    pageSize: data.pageSize,
    pageIndex: data.currentPage,
    schoolName: data.schoolName,
    userRoleId: data.userValueShare,
    name: data.roleName,
    // pageSize: data.pageSize,
    // pageIndex: data.currentPage,
  };
  sysUsers(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.value = newRes.registerButtonSwitch === "open" ? true : false;
        data.spreadTable = newRes.sysUserVOS;
        data.total = newRes.total;
        dealDate(data.spreadTable, ["createTime"]);

        setTimeout(() => {
          data.loading = false;
        }, 300);
      } else {
        setTimeout(() => {
          data.loading = false;
          ElMessage.error(res.data.msg);
        }, 300);
      }
    })
    .catch(() => {
      data.loading = false;
    });
};

//模糊搜索
export const getUserByNames = (data) => {
  // alert("目前只能根据用户名查询，学校，角色查询暂未开发")
  data.loading = true;
  let parame = {
    schoolName: data.schoolName,
    userRoleId: data.userValueShare,
    name: data.roleName,
    pageSize: data.pageSize,
    pageIndex: data.currentPage,
  };
  sysUsers(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.value = newRes.registerButtonSwitch === "open" ? true : false;
      data.spreadTable = newRes.sysUserVOS;
      data.total = newRes.total;
      dealDate(data.spreadTable, ["createTime"]);

      setTimeout(() => {
        data.loading = false;
      }, 300);
    } else if (res.data.code !== "200") {
      data.loading = false;
    } else {
      setTimeout(() => {
        data.loading = false;
        ElMessage.error(res.data.msg);
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    id: item.id,
  };
  deleteInfo(parame).then((res) => {
    if (res.data.code === "200") {

      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该用户, 是否继续?", "警告！", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "重置") {
    ElMessageBox.confirm("是否重置密码为 '123456' ?", "警告！", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      resets(val.item, data)
    })

  }
  if (val.type === "设置角色") {
    dealRole(val.item, data);
  }
};

// 设置角色
export const dealRole = (val, data) => {
  getRoleList(data);
  data.showRoleDia = true;
  data.userItem = val;
  data.userValue = val.roleName;
};

// 确认修改
export const saveInfos = (data) => {
  let parame = {
    id: data.userItem.id,
    userRoleId: data.roleNames,
  };
  saveSysUserRole(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.userItem = {};
      data.roleNames = '';
      backInfo(data);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
// 取消修改
export const backInfo = (data) => {
  data.userValue = "";
  data.showRoleDia = false;
};

// 查找所有角色
export const getRoleList = (data) => {
  let parame = {
    pageSize: 1000,
    pageIndex: 1,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.userOptions = newRes.sysRolePageInfo.list;
      // data.userValue =  data.userOptions.roleName;
    } else {
      data.showRoleDia = false
    }
    if (res.data.total === 696) {
      data.showRoleDia = false
    }
    // let list = res.data.data.sysRolePageInfo.list;
    //   list.forEach(item => {
    //     if(item.roleName === "超级管理员" || "学校管理员"){
    //       // data.operation = []
    //     }
    //   })
  });
};
// 注册开关
export const controls = (data) => {
  let parame = {
    registerButtonSwitch: data.value ? "open" : "close",
  };
  registerButtonSwitch(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data;
      ElMessage.success(res.data.msg);
    }
  });
};
// 批量导入成功
// export const flieSucceses = (res, data) => {
//   if (res.data.code === "200") {
//     let newRes = res.data;
//     data.dialogVisible = false;
//     ElMessage.success(res.data.msg);
//   } else {
//     ElMessage.error("导入失败");
//     data.dialogVisible = false;
//   }
// };
// 模板下载

export const downloadTemplates = (data) => {
  let url = '/static/userTemplate.xlsx';
  let user = unescape(decodeURI(sessionStorage.getItem('logger_tv'))).slice(3);
  if (user === '超级管理员') {
    url = '/static/adminTemplate.xlsx'
  }

  var a = document.createElement('a');
  a.download = '用户导入模板';
  a.href = url;
  document.body.appendChild(a)
  a.click()
  a.remove();
  // if (res.data.code === "200") {
  //   let newRes = res.data;
  //   ElMessage.success(res.data.msg);
  // } else {
  //   ElMessage.error("导入失败");
  // }
};

// 点击批量导入查询学校
export const batchImports = (uploadImport,data) => {
  data.dialogVisible = true;
  data.uploadLoading = false;
  nextTick(() => { uploadImport.value.clearFiles(); })
  let parame = {
    schoolName: "",
    pageSize: 10000,
    pageIndex: 1,
  };
  getSchools(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.schoolNamess = newRes.schoolPageInfo.list;
    }
  });
};

//上传
export const confirmImports = (fdParams, data) => {
  importUsersInBulk(fdParams).then((res) => {
    if (res.data.status == -1) {
      ElMessage.error(res.data.msg);
      data.uploadLoading = true;
      data.uploadLoading2 = false;
    } else {
      data.dialogVisible = false;
      ElMessage.success(res.data.msg);
      nextTick(() => {
        uploadImport.value.clearFiles();
      });
    }
  });
};

// 重置按钮
export const resets = (item,data) => {
  let parame = {
    id:item.id
  };
  resetPassword(parame).then((res) => {
    if(res.data.code == '200'){
      ElMessage.success(res.data.msg)
    }else {
      ElMessage.error(res.data.msg)
    }
  })
}